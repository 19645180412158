<template>
  <v-app class="d-inline">
    <div class="card card-stretch gutter-b">
      <div class="card-body pt-3 pb-7">
        <div class="row">
          <div v-if="isAdmin" class="col-md-3">
            <v-autocomplete
              v-model="mandanteID"
              :items="mandantes"
              item-value="mandanteID"
              item-text="mandanteNombre"
              label="Mandante"
              dense
              @change="searchReportsByMandante()"
            ></v-autocomplete>
          </div>
        </div>
        <div class="row">
          <v-col cols="12">
            <v-data-table
              :headers="headerReportes"
              :items="reportes"
              :search="searchReporte"
              item-key="key"
              disable-pagination
              hide-default-footer
              class="table-striped elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Listado de Reportes</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="isAdmin"
                    dark
                    class="mb-2"
                    v-on:click="showNewReportDialog()"
                  >
                    Nuevo Reporte
                  </v-btn>
                  <v-dialog
                    v-model="newReportDialog"
                    persistent
                    max-width="50%"
                  >
                    <v-card>
                      <v-card-title>
                        <span class="headline">
                          Nuevo Reporte - Mandante: {{ mandanteName }}
                        </span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                label="Nombre de reporte"
                                v-model="reportName"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-textarea
                                label="Descripcion de reporte"
                                v-model="reportDescription"
                              ></v-textarea>
                            </v-col>
                            <v-col
                              cols="12"
                              class="text-uppercase font-weight-medium font-size-lg text-md-center"
                            >
                              <u>Datos de Power BI</u>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                label="ID de Grupo"
                                v-model="powerBiWorkspaceId"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                label="ID Reporte"
                                v-model="powerBiReportId"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-on:click="closeNewReportDialog"
                          >Cerrar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          v-on:click="saveReport"
                          >Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.visible`]="{ item }">
                {{ item.visible ? "SI" : "NO" }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="mr-2 cursor-pointer"
                  v-on:click="mostrarReporte(item)"
                >
                  mdi-eye
                </v-icon>

                <v-icon
                  v-if="isAdmin"
                  small
                  class="mr-2 cursor-pointer"
                  v-on:click="deleteReport(item)"
                >
                  mdi-delete
                </v-icon>

                <v-icon
                  v-if="isAdmin"
                  small
                  class="mr-2 cursor-pointer"
                  v-on:click="openUpdateReportDialog(item)"
                >
                  mdi-pencil-box-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="dialogReport"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn icon dark @click="closeReport()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Reporte</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="p-0">
          <div
            id="powerBIContainer"
            style="height: 100vh; width: 100%"
            frameborder="0"
            allowFullScreen="true"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateReportDialog" persistent max-width="50%">
      <v-card>
        <v-card-title>
          <span class="headline"> Reporte - Mandante: {{ mandanteName }} </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre de reporte"
                  v-model="reportName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Descripcion de reporte"
                  v-model="reportDescription"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                class="text-uppercase font-weight-medium font-size-lg text-md-center"
              >
                <u>Datos de Power BI</u>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="ID de Grupo"
                  v-model="powerBiWorkspaceId"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="ID Reporte"
                  v-model="powerBiReportId"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch v-model="powerBivisibility"></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text v-on:click="closeUpdateReportDialog"
            >Cerrar
          </v-btn>
          <v-btn color="blue darken-1" text v-on:click="updateReport">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import KTUtil from "@/assets/js/components/util";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_MANDANTES,
  GET_REPORTES_POWER_BI,
  GET_POWER_BI_REPORT,
  SAVE_POWER_BI_REPORT,
  UPDATE_POWER_BI_REPORT,
  DELETE_POWER_BI_REPORT,
} from "@/core/services/store/mandante.module";
import Swal from "sweetalert2";
import * as pbi from "powerbi-client";
export default {
  data() {
    return {
      uniqueID: null,
      dialogReport: false,
      mandanteID: null,
      mandantes: [],
      reportes: [],
      reporte: {},
      headerReportes: [
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Descripcion",
          value: "descripcion",
        },
        {
          text: "Visible",
          value: "visible",
        },
        {
          text: "Ultima Actualizacion",
          value: "ultimaActualizacion",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      searchReporte: "",
      powerBIConfig: {
        type: "report",
        tokenType: pbi.models.TokenType.Embed,
        accessToken: undefined,
        embedUrl: undefined,
        id: undefined,
        permissions: pbi.models.Permissions.All,
        viewMode: pbi.models.ViewMode.View,
      },
      newReportDialog: false,
      reportName: "",
      reportDescription: "",
      powerBiWorkspaceId: "",
      powerBiReportId: "",
      powerBivisibility: null,
      updateReportDialog: false,
      powerbi: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Analisis", route: "analisis" },
    ]);

    const { mandanteID: mandanteId } = this.currentUserPersonalInfo;

    this.mandanteID = mandanteId === "null" ? null : mandanteId;
  },
  created() {
    const oVue = this;
    if (Number(oVue.currentUserPersonalInfo.perfil_id) === 2) {
      oVue.getReportes(oVue.currentUserPersonalInfo.mandanteID);
    } else {
      oVue.getMandantes();
    }
  },
  methods: {
    getMandantes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_MANDANTES).then((mandantes) => {
        oVue.mandantes = mandantes;
        KTUtil.removeLoading();
      });
    },
    async getReportes(iMandanteID) {
      KTUtil.setLoading();
      let oVue = this;
      const reports = await oVue.$store.dispatch(
        GET_REPORTES_POWER_BI,
        iMandanteID
      );
      oVue.reportes = this.isAdmin
        ? reports
        : reports.filter(({ visible }) => visible === true);
      KTUtil.removeLoading();
    },
    searchReportsByMandante() {
      this.getReportes(this.mandanteID);
    },
    async getReporte(iReporteID) {
      const oVue = this;
      KTUtil.setLoading();
      try {
        const result = await oVue.$store.dispatch(
          GET_POWER_BI_REPORT,
          iReporteID
        );
        oVue.reporte = result;
        return result;
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        KTUtil.removeLoading();
      }
    },
    async mostrarReporte(aReporte) {
      const oVue = this;

      oVue.closeReport();

      oVue.reporte = aReporte;
      const config = await oVue.getReporte(aReporte.id);
      const powerBIConfig = {
        ...oVue.powerBIConfig,
        ...config,
        id: config.reportId,
      };

      oVue.dialogReport = true;

      await this.$nextTick();

      this.powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );
      const powerBIContainer = document.getElementById("powerBIContainer");
      let report;
      try {
        report = this.powerbi.embed(powerBIContainer, powerBIConfig);
      } catch (error) {
        await this.$nextTick();
        report = this.powerbi.embed(powerBIContainer, powerBIConfig);
      }

      // Report.off removes a given event handler if it exists.
      report.off("loaded");

      // Report.off removes a given event handler if it exists.
      report.off("rendered");

      report.on("error", function (event) {
        console.log("an error has ocurred");
        console.log(event?.detail);
        report.off("error");
      });

      report.off("saved");
    },
    closeReport() {
      const oVue = this;

      if (this.powerbi) {
        const powerBIContainer = document.getElementById("powerBIContainer");
        this.powerbi.reset(powerBIContainer);
        document.getElementById("powerBIContainer").innerHTML = "";
      }

      oVue.reporte = {};
      oVue.dialogReport = false;
    },
    showNewReportDialog() {
      if (!this.mandanteID) {
        Swal.fire({
          title: "",
          text: "Debe seleccionar un mandante",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });

        return;
      }
      this.newReportDialog = true;
    },
    closeNewReportDialog() {
      this.reportName = "";
      this.reportDescription = "";
      this.powerBiWorkspaceId = "";
      this.powerBiReportId = "";
      this.newReportDialog = false;
    },
    async saveReport() {
      try {
        KTUtil.setLoading();
        const payload = {
          mandanteId: this.mandanteID,
          name: this.reportName,
          description: this.reportDescription,
          workspaceId: this.powerBiWorkspaceId,
          reportId: this.powerBiReportId,
        };

        await this.$store.dispatch(SAVE_POWER_BI_REPORT, payload);

        Swal.fire({
          title: "¡Enhorabuena!",
          text: "El reporte fue creado",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.searchReportsByMandante();
      } catch (error) {
        console.log("Something went wrong!", error);
      } finally {
        this.closeNewReportDialog();
        KTUtil.removeLoading();
      }
    },
    closeUpdateReportDialog() {
      this.reportName = "";
      this.reportDescription = "";
      this.powerBiWorkspaceId = "";
      this.powerBiReportId = "";
      this.powerBivisibility = null;
      this.uniqueID = null;

      this.updateReportDialog = false;
    },
    openUpdateReportDialog(report) {
      this.closeNewReportDialog();

      this.reportName = report?.nombre;
      this.reportDescription = report.descripcion;
      this.powerBiWorkspaceId = report.workspaceId;
      this.powerBiReportId = report.reportId;
      this.powerBivisibility = report.visible;
      this.uniqueID = report.id;

      this.updateReportDialog = true;
    },
    async updateReport() {
      try {
        KTUtil.setLoading();
        const payload = {
          id: this.uniqueID,
          name: this.reportName,
          description: this.reportDescription,
          workspaceId: this.powerBiWorkspaceId,
          reportId: this.powerBiReportId,
          visible: this.powerBivisibility,
        };

        await this.$store.dispatch(UPDATE_POWER_BI_REPORT, payload);

        Swal.fire({
          title: "¡Enhorabuena!",
          text: "El reporte fue actualizado",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.searchReportsByMandante();
      } catch (error) {
        console.log("Something went wrong!", error);
      } finally {
        this.closeUpdateReportDialog();
        KTUtil.removeLoading();
      }
    },
    async deleteReport(report) {
      const { isConfirmed: result } = await Swal.fire({
        title: "¿Esta seguro que desea eliminar el reporte?",
        text: "Los cambios no se pueden modificar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "NO",
        confirmButtonText: "SI",
      });

      if (!result) return;

      await this.$store.dispatch(DELETE_POWER_BI_REPORT, { id: report.id });

      Swal.fire({
        title: "¡Enhorabuena!",
        text: "El reporte fue actualizado",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });

      this.searchReportsByMandante();
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isAdmin() {
      return Number(this.currentUserPersonalInfo.perfil_id) === 1;
    },
    mandanteName() {
      if (!this.mandanteID) return "";

      return this.mandantes.find(
        (mandante) => mandante.mandanteID === this.mandanteID
      )?.mandanteNombre;
    },
  },
};
</script>
